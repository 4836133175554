@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .modal-parent {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 100;
    }
    .modal {
        padding: 20px;
        width: 30%;
        background-color: #0f0f11;
        border-radius: 10px;
    }
    .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
        margin-top: 4px;
    }
    .modal-header img {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .modal-header h1 {
        font-size: 24px;
        margin: 0;
    }
    .modal form {
        display: flex;
        flex-direction: column;
        background-color: #0f0f11;
        border-radius: 10px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        justify-content: space-between;
    }
    .modal #submit {
        background-color: #5865f2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        margin-top: 10px;
        cursor: pointer;
        font-family: "Inter";
        font-size: "18px";
        font-weight: 300;
        float: right;
    }
}
