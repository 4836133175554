@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .sidebar-parent {
        background-color: #0f0f11;
        padding: 20px 0;
        color: white;
        overflow: hidden;
    }
    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 30px;
    }
    .server {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        position: relative;
    }
    .server-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        /* margin-bottom: 20px; */
    }
    .list-dropdown img {
        border-radius: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .list-dropdown {
        transition: all 0.3s ease-in-out;
    }
    .notfolded .list-dropdown {
        rotate: -90deg;
        transition: all 0.3s ease-in-out;
    }
    .server-info img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
    .server h1 {
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .notfolded .sidebar-container ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .sidebar ul li a {
        text-decoration: none;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px;
        margin: 10px 0;
    }
    .sidebar ul li a:hover {
        background-color: #18191c;
        border-radius: 10px;
    }
    .sidebar ul li a img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    .sidebar-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        transform: translateY(-20px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    .folded .sidebar-container {
        display: none;
    }
    .notfolded .sidebar-container {
        display: flex;
        opacity: 1;
        animation: fadeIn 0.3s ease-in-out forwards;
        overflow: hidden;
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
