body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

main {
    margin-top: 100px;
}

@media screen and (min-width: 1300px) {
    .overview-page {
        display: flex;
        flex-direction: row;
    }
    .logging-page {
        display: flex;
        flex-direction: row;
    }
    .automoderation-page {
        display: flex;
        flex-direction: row;
    }
    .moderation-page {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 1300px) {
    .overview-page {
        display: flex;
        flex-direction: column;
    }
    .logging-page {
        display: flex;
        flex-direction: column;
    }
    .automoderation-page {
        display: flex;
        flex-direction: column;
    }
    .moderation-page {
        display: flex;
        flex-direction: column;
    }
}
