@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .censors {
        background-color: #2c2f33;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .censor {
        background-color: #747f8d;
        padding: 2px 5px;
        border-radius: 5px;
    }
}
