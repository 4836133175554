@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .overview {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 40px;
        width: 100%;
    }
    .guild-parent {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #0f0f11;
        width: 90%;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        gap: 20px;
        margin-bottom: 20px;
    }
    .guild-parent img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .guild-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .guild-info h1 {
        font-size: 30px;
        margin: 0;
    }
    .guild-info p {
        margin: 0;
    }
}
