@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .automoderation {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 40px;
        width: 100%;
        margin-bottom: 40px;
    }
    .automods {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        grid-gap: 20px;
        width: 90%;
    }
    .container {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        background-color: #0f0f11;
        border-radius: 10px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        /* understand this */
        box-sizing: border-box;
        justify-content: space-between;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .settings {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 10px;
    }
    .select-setting {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .item {
        width: 100%;
    }
    .item p {
        margin-bottom: 10px;
        margin-left: 2px;
        font-weight: 600;
    }
    .select-skeleton {
        height: 38px;
        border-radius: 4px !important;
    }
    .parent-skeleton {
        line-height: 1 !important;
    }
    .input-setting-parent {
        display: flex;
        max-width: 100%;
    }
    .input-setting {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #2c2f33;
        color: white;
        border-radius: 4px;
        border: 1px solid #2c2f33;
        font-size: 14px;
        height: 34px;
        padding-left: 10px;
        width: 100%;
        max-height: 34px;
    }
    .input-setting:hover {
        border-color: hsl(0, 0%, 70%);
        border-width: 0.5px;
    }
    .input-setting:focus {
        outline: none;
        border: outset;
        border: 1px solid #3182ce;
    }
    /* Chrome, Safari, Edge, Opera */
    .input-setting-parent input::-webkit-outer-spin-button,
    .input-setting-parent input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    .input-setting-parent input[type="number"] {
        -moz-appearance: textfield;
    }
}
