@import url("../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .moderation {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 40px;
        width: 100%;
        margin-bottom: 40px;
    }
}
