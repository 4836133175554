@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .guilds {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .in-guilds,
    .not-in-guilds {
        /* understand this please */
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 20px;
        /* -- */
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 50px;
        justify-content: space-between;
        width: 100%;
    }
    .guild-card {
        background-color: #0e0e0e;
        color: white;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .guild-card:hover {
        background-color: #070707;
        transform: scale(1.04);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    .guild-card img {
        /* border-radius: 10px; */
        border-radius: 100%;
        width: 100px;
        height: 100px;
    }
    .guild-card h1 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 0;
    }
    .card-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .text-button {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        justify-content: space-around;
        height: 100%;
        width: 100%;
    }
    .text-button h1 {
        font-size: 20px;
        margin: 0;
    }
    .text-button button {
        /* background-color: #5865f2; */
        background-color: #4f545c;
        border: none;
        border-radius: 10px;
        padding: 10px;
        color: white;
        font-size: 16px;
        font-family: "Inter";
        font-weight: 300;
        width: 100%;
    }
    #servers {
        font-size: 36px;
        color: white;
    }
    .text-button button:hover {
        cursor: pointer;
    }
    .skeleton-item {
        height: 150px;
        border-radius: 10px !important;
    }
    .guilds-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        margin-top: 20px;
        width: 100%;
    }
    .guilds-header img {
        cursor: pointer;
    }
}
