@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Light.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Ginto";
    src: url("../fonts/ABCGintoNormal-Bold-Trial-BF651b7b7309f45.woff")
        format("woff");
    font-weight: bold;
}

@font-face {
    font-family: "Consolas";
    src: url("../fonts/Consolas.ttf") format("truetype");
}
