@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .card {
        background-color: #0f0f11;
        padding: 10px 20px;
        font-family: "Inter";
        font-weight: 200;
        color: white;
        width: 90%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }
    .card-header h2 {
        margin: 10px 0px;
        font-size: 26px;
    }
    .card-header img {
        cursor: pointer;
    }
    .card-list {
        list-style-type: none;
        background-color: #2c2f33;
        padding: 0;
        margin: 0;
        /* could be a problem? */
        border-radius: 10px;
    }
    .card-list li {
        padding: 10px;
        border-bottom: 2px solid #0f0f11;
    }
    .card-list li:last-child {
        border-bottom: none;
    }
    .card-buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    .card-button {
        padding: 10px 15px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        border: none;
        color: white;
        font-family: "Inter";
        font-weight: 200;
        font-size: 14px;
    }
    #add {
        background-color: #5865f2;
    }
    #remove {
        background-color: #36393f;
    }
    .card-skeleton {
        display: inline-block;
        padding: 10px 20px;
        height: 160px;
        width: 90% !important;
        border-radius: 10px !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin: 20px 0;
    }
}
