@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .rti--container {
        --rti-bg: #2c2f33 !important;
        --rti-border: none !important;
        --rti-main: #3182ce !important;
        --rti-tag: #747f8d !important;
        --rti-tag-remove: red !important;
        border-radius: 5px !important;
    }
    .rti--input {
        background-color: #2c2f33;
        color: white;
        font-family: "Inter";
        font-weight: 100;
        width: 100% !important;
    }
    .rti--tag {
        padding: 2px 6px !important;
        font-family: "Inter" !important;
        font-weight: 200 !important;
    }
    .rti--tag button {
        margin-top: 0 !important;
        padding-right: 4px !important;
    }
    .modal h3 {
        font-family: "Inter";
        font-weight: 400;
        font-size: 18px;
        color: white;
        margin-bottom: 8px;
    }
    .modal .selector {
        margin-bottom: 10px;
    }
}
