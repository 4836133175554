@import url("../fonts.css");
@media screen and (min-width: 1300px) {
    .stats-parent {
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        font-family: "Inter";
        font-weight: 400;
    }
    .stats {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        padding: 10vh 0;
    }
    .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
    }
    .stat h1 {
        margin-bottom: 0;
    }
    .stat span {
        font-size: 54px;
    }
}
