@import url("../fonts.css");
@import url("../root.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    [class^="section"] {
        display: flex;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 15%;
        width: 84%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        justify-content: space-between;
        color: white;
    }
    .sec-text {
        text-align: left !important;
    }
    .sec-text h1 {
        font-size: 40px;
        line-height: 110%;
        font-family: "Ginto";
    }
    .sec-text p {
        font-size: 16px;
    }
    .sec-image img {
        width: 100%;
        border-radius: 10px;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
        margin-top: 2rem;
    }
    .sec-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
    }
    .sec-buttons button {
        border: none;
        border-radius: 8px;
        padding: 10px 20px;
        margin-right: 1rem;
        color: white;
        text-decoration: none;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 200;
        cursor: pointer;
        text-decoration: none;
    }
    .sec-text span {
        font-weight: bold;
        background: linear-gradient(to right, #a31afc, #ce3f85, #ff6900);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 40px;
        line-height: 110%;
        font-family: "Ginto";
        display: inline-block;
        margin: 0;
    }
    #home1 {
        margin-top: 12%;
        margin-bottom: 10%;
    }
    #home2 {
        margin-top: 8%;
        margin-bottom: 10%;
    }
}
