@import url("../fonts.css");
@media screen and (min-width: 1300px) {
    [class^="section"] {
        display: flex;
        align-items: center;
        margin-top: 15%;
        margin-bottom: 15%;
        width: 70%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        justify-content: space-between;
    }
    .section-right {
        flex-direction: row;
    }
    .section-right img {
        float: right;
    }
    .section-left {
        flex-direction: row-reverse;
    }
    .section-left img {
        float: left;
    }
    .sec-text {
        display: flex;
        flex-direction: column;
        color: white;
        width: 35%;
        text-align: left;
        height: 100%;
        justify-content: space-between;
    }
    .sec-text h1 {
        font-size: 46px;
        line-height: 110%;
        font-family: "Ginto";
    }
    .sec-text p {
        font-size: 16px;
    }
    .sec-image {
        width: 40vw;
    }
    .sec-image img {
        width: 90%;
        height: 100%;
        object-fit: contain;
        border-radius: 2rem;
        box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.4);
    }
    .sec-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
    }
    .sec-buttons button {
        border: none;
        border-radius: 8px;
        padding: 10px 20px;
        margin-right: 1rem;
        color: white;
        text-decoration: none;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 200;
        cursor: pointer;
        text-decoration: none;
    }
    .sec-text span {
        font-weight: bold;
        background: linear-gradient(to right, #a31afc, #ce3f85, #ff6900);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 46px;
        line-height: 110%;
        font-family: "Ginto";
        display: inline-block;
        margin: 0;
    }
    #home1 {
        margin-top: 12%;
        margin-bottom: 10%;
    }
    #home2 {
        margin-top: 8%;
        margin-bottom: 10%;
    }
}
