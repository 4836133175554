@import url("../fonts.css");
@media screen and (max-width: 1300px) {
    footer {
        /* background-color: rgb(14, 14, 14); */
        background-color: black;
        font-family: "Inter";
        font-weight: 200;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        text-align: left !important;
        color: white;
        padding-bottom: 50px;
        padding-top: 20px;
    }
    footer .left {
        display: flex;
        flex-direction: column;
        color: white;
        justify-content: space-between;
        margin-left: 8%;
    }
    footer .right {
        display: flex;
        flex-direction: column;
        margin-left: 8%;
        margin-bottom: 8%;
    }
    footer .right ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    footer .right li {
        padding-top: 10px;
    }
    footer .right ul a {
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
        font-family: "Inter";
        font-weight: 100;
    }
}
