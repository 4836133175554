@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .sidebar-parent {
        width: 350px;
        background-color: #0f0f11;
        padding: 20px 0;
        margin-top: -20px;
        color: white;
        align-items: center;
    }
    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .server {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #18191c;
        padding: 20px 0px;
        border-radius: 10px;
        width: 90%;
        margin-bottom: 20px;
    }
    .server-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .server img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
    }
    .server h1 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 0;
    }
    .sidebar ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .sidebar ul li a {
        text-decoration: none;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px;
        margin: 10px 0;
    }
    .sidebar ul li a:hover {
        background-color: #18191c;
        border-radius: 10px;
    }
    .sidebar ul li a img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    .sidebar-container {
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 90%;
    }
    /* temp */
    .sidebar-parent {
        height: 110vh;
    }
    .list-dropdown {
        display: none;
    }
}

/* @media screen and (max-height: 900px) {
    .sidebar-parent {
        height: auto;
    }
}

@media screen and (min-height: 900px) {
    .sidebar-parent {
        height: 100vh;
    }
} */
