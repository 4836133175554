@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .overview {
        display: flex;
        flex-direction: column;
        color: white;
        width: 84%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 40px;
    }
    .guild-parent {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #0f0f11;
        padding: 20px;
        border-radius: 10px;
        gap: 20px;
    }
    .guild-parent img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .guild-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .guild-info h1 {
        font-size: 30px;
        margin: 0;
    }
    .guild-info p {
        margin: 0;
    }
}
