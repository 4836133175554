@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .unknown-error {
        font-family: "Inter";
        color: white;
        font-weight: 100;
        font-size: 24px;
        margin-left: 40px;
    }
    .unknown-error button {
        background-color: #36393f;
        color: white;
        font-family: "Inter";
        font-weight: 200;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    .wumpus {
        /* move to bottom right */
        position: absolute;
        bottom: 0;
        left: 60%;
    }
}
