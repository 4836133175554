@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .shortcuts {
        background-color: #2c2f33;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .shortcut-name {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 6px;
        display: inline-block;
    }
}
