@import url("../fonts.css");
@import url("../root.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .navbar-closed .navbar-container {
        display: none;
    }
    .navbar-closed .navbar-user {
        display: none !important;
    }
    .navbar-closed .close {
        display: none;
    }
    .navbar .user {
        display: block;
    }
    .navbar-closed .user {
        display: none;
    }
    .navbar-container .close {
        display: block;
    }
    .navbar-closed .hamburger {
        display: block !important;
    }
    .navbar .hamburger {
        display: none;
    }
    .navbar-open .user {
        display: none;
    }
    .navbar-open {
        border-radius: 0 0 10px 10px;
    }
    .navbar {
        top: 0;
        position: fixed;
        z-index: 1;
        width: 100%;
        background-color: black;
        flex-direction: column;
        font-family: "Inter";
        padding-bottom: 30px;
    }
    .navbar::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(to right, #a31afc, #ce3f85, #ff6900);
    }
    .navbar .dynamic-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbar .brand {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        margin-left: 8%;
    }
    .navbar .dynamic-nav .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        margin-right: 8%;
    }
    .navbar .dynamic-nav .user img {
        border-radius: 32px;
        margin-left: 20px;
    }
    .navbar .hamburger {
        /* margin-top: 30px;
        margin-right: 8%; */
        /* transform: rotate(90deg); */
        transition: all 0.3s ease-in-out;
    }
    .navbar .close {
        transition: all 0.3s ease-in-out;
    }
    .navbar .hamburger:hover {
        cursor: pointer;
    }
    .navbar .close:hover {
        cursor: pointer;
    }
    .navbar .list {
        margin: 0;
        display: flex;
        align-items: center;
    }
    .navbar ul {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 8%;
        padding: 0;
    }
    .navbar li {
        list-style-type: none;
        text-align: left;
        padding: 15px 0px;
    }
    .navbar li a {
        text-decoration: none;
        color: #aaaaaa;
        font-weight: 300;
    }
    .navbar li a:hover {
        color: #ffffff;
    }
    .navbar p {
        margin: 0;
        margin-left: 20px;
        color: white;
        font-size: 24px;
        font-weight: normal;
    }
    .navbar .divider-parent {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .navbar .divider {
        height: 3px;
        width: 84%;
        border-radius: 10px;
        background-color: white;
    }
    .signin {
        background-color: #5865f2;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 30px;
        margin-left: 8%;
        display: flex;
    }
    .signin a {
        text-decoration: none;
    }
    .navbar-container a {
        text-decoration: none;
    }
    .navbar-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        margin-left: 8%;
    }
    .navbar-user img {
        margin: 0;
        border-radius: 32px;
    }
    .signout {
        background-color: #ed4245;
        padding: 10px 20px;
        color: white;
        border: none;
        border-radius: 10px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 300;
    }
    .signout:hover {
        cursor: pointer;
    }
    .icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .premium-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
    }
    #premium-desktop {
        display: none;
    }
}
