@import url("../../../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .modal .textbox {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .textbox .char-count {
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: 12px;
        color: #747f8d;
        padding: 2px 5px;
    }
    .textbox .regex-input {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #2c2f33;
        border-radius: 4px;
        font-size: 16px;
        resize: none;
        font-family: "Consolas";
        background-color: #2c2f33;
        color: white;
        resize: vertical;
        min-height: 80px;
    }
    .textbox .regex-input:focus {
        outline: none;
        border: 1px solid #3182ce;
    }
}
