@import url("../fonts.css");
@import url("../root.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (min-width: 1300px) {
    .navbar {
        background-color: black;
        padding: 20px 15%;
        font-family: "Inter";
        font-weight: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        top: 0;
        display: flex;
        flex-direction: row;
    }
    .navbar::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(to right, #a31afc, #ce3f85, #ff6900);
    }
    .navbar-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .navbar .hamburger {
        display: none;
    }
    .navbar .user {
        display: none;
    }
    .navbar .close {
        display: none;
    }
    .navbar p {
        margin: 0;
        margin-left: 20px;
        color: white;
        font-size: 24px;
    }
    .navbar .brand {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .navbar ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
    }
    .navbar li {
        display: inline;
        justify-content: space-between;
        margin: 0 20px;
    }
    .navbar li a {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: #aaaaaa;
        width: 100%;
        height: 100%;
    }
    .navbar li a:hover {
        color: #ffffff;
    }
    .list {
        display: flex;
        flex-direction: flex-start;
        align-items: center;
        margin-left: 20px;
    }
    .navbar-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        color: white;
    }
    .navbar-user img {
        margin: 0;
        border-radius: 32px;
    }
    .navbar-user p {
        font-family: "Inter";
        font-weight: normal;
        font-size: 16px;
        margin: 0;
        margin-left: 10px;
    }
    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .signin {
        background-color: #5865f2;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        margin-left: auto;
    }
    .signout {
        background-color: #36393f;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        margin-left: auto;
        font-family: "Inter";
        font-weight: 200;
        margin-left: 10px;
    }
    .premium-button {
        background: linear-gradient(to right, #997530, #b39951);
        color: white;
        border-radius: 10px;
        padding: 8px 20px;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-family: "Inter";
        font-weight: 200;
    }
    .premium-button img {
        margin-right: 10px;
        border-radius: 0 !important;
    }
    #premium-mobile {
        display: none;
    }
}
