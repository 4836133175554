@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .toggle-switch {
        width: 45px;
        height: 25px;
        border-radius: 20px;
        background-color: #ccc;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    .toggle-switch.on {
        background-color: #4caf50;
    }
    .toggle-switch.off {
        background-color: #999999;
    }
    .toggle-switch-handle {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3.5px;
        transition: left 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .toggle-switch.on .toggle-switch-handle {
        left: calc(100% - 21.5px);
    }
    .check-icon {
        color: #4caf50;
    }
    .x-icon {
        color: #999999;
    }
}
