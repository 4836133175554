@import url("../fonts.css");
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@media screen and (max-width: 1300px) {
    .logging {
        display: flex;
        flex-direction: column;
        color: white;
        width: 84%;
        margin-bottom: 40px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .select-channel {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 15px;
    }
    .select {
        color: white;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 200;
    }
    .select h1 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;
        color: white;
        margin-top: 0;
    }
    .selects {
        display: flex;
        flex-direction: column;
    }
    .select-container {
        background-color: #0f0f11;
        padding: 25px 20px;
        border-radius: 10px;
        margin-top: 0;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    }
    .select-container p {
        margin-top: 20px;
        margin-bottom: 0px;
    }
    #modlog {
        margin-bottom: 20px;
    }
    .container-skeleton {
        display: inline-block;
        padding: 10px 20px;
        height: 160px;
        width: 90% !important;
        border-radius: 10px !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin: 20px 0;
    }
}
